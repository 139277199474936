import { useEffect, useRef, useState } from "react"
import "./styles.less"
import { getLocations } from "src/services/search";
import { LoadingIcon } from "shared-components/Components/CustomIcons/LoadingIcon/LoadingIcon";
import { getOptionLabel } from "shared-components/Components/Filters/KeywordLocation/web";
import { LocationOutlinedIcon } from "shared-components/Components/CustomIcons/LocationOutlineIcon/web";
import { SearchIcon } from "shared-components/Components/CustomIcons/SearchIcon/web";
import { useHomeFilters } from "shared-components/ViewFragments/Home/HomeFilters/HomeFilters.hook";
import { getFormattedFilters } from "src/utils/Functions";
import { encodeHashUrl } from "@Utils/Functions";
import { useRouter } from "next/router";
import useLocationExtraData from "src/hooks/locationExtraData/useLocationExtraData";
import { useSearchResultsFilters } from "shared-components/ViewFragments/SearchResults/Sider/Filters/SearchResultsFilters.hook";
import { LocationData } from "./types";

type LocationSearchProps = {
    showSearchBtn: boolean,
    isFromHome?: boolean
}

export const LocationSearch = ({ showSearchBtn, isFromHome } : LocationSearchProps) => {
	const { filters, changeFilters, filtersTags, search } = useHomeFilters();
    const { changeFiltersAndUpdateURL } = useSearchResultsFilters();
	const { setLocationText, locationText } = useLocationExtraData();
	const router = useRouter();
    const optionRefs = useRef([]);
    
    const [options, setOptions] = useState<LocationData[]>([]);
    const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
    const [isSearchingOption, setIsSearchingOption] = useState<boolean>(false);
	const [debounceTimeOut, setDebounceTimeOut] = useState(null);
	const [text, setText] = useState<string>(locationText);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [optionsHovered, seOptionsHovered] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState(-1);

    const textLocationTransform = (location:LocationData)=>{
        let locationTransformed
        if(location.name){
            locationTransformed = location
        }
        else {
            locationTransformed = {...location,name:""}
        }
        return getOptionLabel(locationTransformed)
                .replace(/<br\s*\/?>/gi, ', ')
                .replace(/<\/?[^>]+>/g, '')
                .replace(/\s+,/g, ',')
                .replace(/\s+/g, ' ')
                .trim() ?? "";
    }

    const locationLabel = filters?.locations?.length ?  textLocationTransform(filters?.locations[0]) : undefined;

    const clearLocation = () => {
		setLocationText("");
		setText("");
		setShowOptions(false);
		setOptions([]);
	};

	useEffect(() => {
		if (!filters?.locations?.length) {
			setText("");
			setLocationText("");
		}
	}, [filters?.locations]);

	useEffect(() => {
		if (locationLabel) {
			setLocationText(locationLabel);
			setText(locationLabel);
		}

		if (isFromHome) clearLocation();
	}, []);

    const handleSearchChange = (evt) => {
        const { value } = evt.target;
		const valueCleaned = value.replace(/[^\w\sáéíóúÁÉÍÓÚñÑ˜´]/g, '');

        setIsSearchingOption(true);
        setText(valueCleaned);

        if (debounceTimeOut != null) {
			clearTimeout(debounceTimeOut);
		}

        setDebounceTimeOut(
            setTimeout(() => {
                getLocations(valueCleaned)
                    .then(data => {
                        setOptions(data.searchLocation)
                        setShowOptions(true);
                        setIsSearchingOption(false);
                        setIsOnFocus(false);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }, 500)
        );
    }

    const handleOnBlur = () => {
        if (!optionsHovered) {
            setIsOnFocus(false);
            setShowOptions(false);
        }
        setText(locationLabel)
        setLocationText(locationLabel)
        setIsOnFocus(false)
    }

    const handleOnFocus = () => {
        setIsOnFocus(true);
        setLocationText("");
        setText("");
        setShowOptions(false);
        setOptions([]);
        //handleClearSearch();
    }

    const handleClearSearch = () => {
        clearLocation()
		if(!isFromHome){
            changeFiltersAndUpdateURL({
                locations: null,
            })
        }
    }

    const handleSearch = (option) => {
        if (!!options.length) {
            const optionSelected = option ?? options[0];
            const label = textLocationTransform(optionSelected)
            
            setShowOptions(false);
            setOptions([]);
            setLocationText(label)
            setText(label)

            if (isFromHome) {
                changeFilters({
                    locations: [
                        {
                            text: "locations",
                            value: optionSelected,
                        },
                    ]
                });
                search.send({
                    variables: {
                        params: getFormattedFilters({...filters, locations: [optionSelected]})
                    }
                });
            }
            else {
            changeFiltersAndUpdateURL({
                locations: [
                    {
                        text: "locations",
                        value: optionSelected,
                    },
                ]
            });
            }
        } else {
            search.send({
                variables: {
                    params: getFormattedFilters({...filters})
                }
            });
        }
    }

    const handleKeyDown = (event) => {
        if (showOptions) {
            if (event.key === "ArrowDown") {
                setSelectedOption((prevIndex) => 
                    prevIndex < options.length - 1 ? prevIndex + 1 : 0
                );
            } else if (event.key === "ArrowUp") {
                setSelectedOption((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : options.length - 1
                );
            } else if (event.key === "Enter" && selectedOption >= 0) {
                event.preventDefault();

                handleSearch(options[selectedOption]);
            }
        }
    };

    useEffect(() => {
        setText(locationText)
    }, [locationText]);

    useEffect(() => {
        if (search.response.data && !search.response.loading) {
            router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				search.response.data.searchUrl.url
			);
        }
    }, [search.response.data]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [options, selectedOption, showOptions]);

    useEffect(() => {
        if (selectedOption >= 0 && optionRefs.current[selectedOption]) {
            optionRefs.current[selectedOption].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [selectedOption]);
    
    return (
        <div className="location-search__container">
            <div className="location-search__input-container">
                <input
                    type="text"
                    className="location-search__input"
                    placeholder="🔍︎ Busca por ubicación"
                    value={text}
                    onFocus={handleOnFocus}
                    onChange={handleSearchChange}
                    onBlur={handleOnBlur}
                />

                {( text && text.length || (filters.locations?.length)) ?
                    <button className="location-search__clear" onClick={handleClearSearch}>
                        x
                    </button> :
                    null
                }

                {isOnFocus &&
                    <div className="location-search__label">
                        {isSearchingOption ?
                            <span className="location-search__label-loading">
                                <LoadingIcon classes="loading-icon" />
                                Cargando
                            </span> :
                            <span>Escribe una ubicación</span>
                        }
                    </div>
                }

                {showOptions && 
                    <div className="location-search__options"
                        onMouseEnter={() => seOptionsHovered(true)}
                        onMouseLeave={() => seOptionsHovered(false)}
                    >
                        <div className="location-search__options-title">
                            {!!options.length ? "Ubicación" : "No hay resultados"}
                        </div>

                        {!!options.length ?
                            <ul className="location-search__options-list">
                                {options.map((option, index) => {
                                    const label = getOptionLabel(option);
                                    const isSelected = index === selectedOption;

                                    return (
                                        <li
                                            className={`location-search__options-list--item ${isSelected ? "active" : ""}`}
                                            onClick={() => handleSearch(option)}
                                            key={`option-${option.id}`}
                                            ref={(el) => optionRefs.current[index] = el}
                                        >
                                            <span><LocationOutlinedIcon /></span>
                                            <span dangerouslySetInnerHTML={{__html: label}}></span>
                                        </li>
                                    )
                                })}
                            </ul> :
                            <span className="location-search__options-empty">
                                <span><SearchIcon width={16} /></span>
                                No hay resultados
                            </span>
                        }
                    </div>
                }
            </div>

            {showSearchBtn &&
                <div className="search-button-container">
                    <button
                        className="search-button superPrimary btn-primary"
                        onClick={() => handleSearch(null)}
                    >
                        {search.response.loading ? (
                            <LoadingIcon classes="loading-icon" />
                        ) : (
                            <SearchIcon />
                        )}
                    </button>
                </div>
            }
        </div>
    )


}